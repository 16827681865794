import * as firebase from 'firebase/app';
import 'firebase/storage';

// Replace this with your own config details
var config = {
  apiKey: 'AIzaSyDnS6YxLf2smFjtgGNJHWMyIZnm8VyE7ug',
  authDomain: 'digitalcontacts-2699b.firebaseapp.com',
  databaseURL: 'https://digitalcontacts-2699b.firebaseio.com',
  projectId: 'digitalcontacts-2699b',
  storageBucket: 'digitalcontacts-2699b.appspot.com',
  messagingSenderId: '477360376068',
  appId: '1:477360376068:web:3ded5bcdb419142a63a851',
};
firebase.initializeApp(config);

export default firebase;
