import React, { useState } from 'react'
import xclose from '../assets/xclose.png'
import logo from '../assets/DigitalContacts-logo.svg'
import { FaShare } from 'react-icons/fa'
import ReactTooltip from 'react-tooltip'
var QRCode = require('qrcode.react')

export default function QrScan({ user, show, setshow }) {
  const [state, setState] = useState('Copy link to clipboard')
  return (
    <div>
      <div className={`col-12 ${show}`} id="profileQrCon">
        <div className="col-12 text-right pt-4 p-0" onClick={() => setshow('')}>
          <img
            src={xclose}
            width="25"
            className="clsPopup"
            target="#profileQrCon"
            alt="QR CODE"
          />
        </div>
        <div className="col-12 r2 text-center">
          <div className="my-profile-photo">
            <img src={user.avatarUrl} alt="Avatar" id="profileImg" />
          </div>
          <div className="col-12 p-2">
            <h1>
              <b>{user.name}</b>
            </h1>
          </div>
        </div>

        <div className="col-12 text-center r3" width="200">
          <QRCode value={`https://www.digitalcontacts.info/${user.lengthId}`} />
        </div>

        {/* TooltTip functionality */}
        <div className="my-4 d-flex justify-content-center">
          <span
            className="position-relative btn px-3 py-2 "
            style={{
              height: '50px',
              minWidth: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            data-tip=""
            data-for="registerTip34"
            onMouseLeave={() => setState('Copy link')}
            onClick={async () => {
              navigator.clipboard.writeText(user.tinyUrl)
              setState('Copied!')
            }}
          >
            <FaShare className="btn_link" />
            <ReactTooltip id="registerTip34" place="top" effect="solid">
              {state}
            </ReactTooltip>
          </span>
        </div>

        <div className="col-12 text-center r4">
          <b>Scan this code with a camera</b>
          <br />
          <b>to share your Digital Contact Card.</b>
        </div>
        <div className="col-12 text-center r5">
          <img src={logo} width="100" alt="LOGO" />
        </div>
      </div>
    </div>
  )
}
