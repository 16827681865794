import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import AddSocialsView from './Views/AddSocialsView';
import { updateUser, registerUser } from '../actions/registerUser';
import firebase from '../config/fbConfig';
import FileUploader from 'react-firebase-file-uploader';
import Spinner from './Spinner';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { getButton } from './Views/socialstate.js';
import imageCompression from 'browser-image-compression';

const EditProfile = ({ loading, user, updateUser, isAuth }) => {
  useEffect(() => {
    setuser({ ...user, curpassword: '', newpassword: '' });
    setloading(false);
  }, [user]);
  const history = useHistory();
  const [User, setuser] = useState();
  const [Loading, setloading] = useState(true);
  const [imgLoading, setimgLoading] = useState(false);
  const [coverLoading, setcoverLoading] = useState(false);
  const [activeChangePassword, setactiveChangePassword] = useState(false);
  const [showPass, setshowPass] = useState({
    curpassword: false,
    newpassword: false,
  });
  const [toggleGear, setToggleGear] = useState(false);

  const [state, setstate] = useState({
    avatar: '',
    isUploading: false,
    progress: 0,
    avatarURL: '',
    cover: '',
    coverUrl: '',
    imageloading: false,
  });

  const handleUploadSuccess = (filename, cover) => {
    console.log(cover, filename);
    setstate({ ...state, avatar: filename, progress: 100 });
    firebase
      .storage()
      .ref('images')
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        if (cover) {
          setstate({ ...state, coverUrl: url, imageloading: false });
        } else {
          setstate({ ...state, avatarURL: url, imageloading: false });
        }
      });
    setTimeout(() => {
      setimgLoading(false);
      setcoverLoading(false);
    }, 5000);
  };
  const handleFileChange = async (event, cover) => {
    console.log(event.target);
    const imageFile = event.target.files[0];
    console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    console.log(`originalFile size ${imageFile.size / 1024} KB`);
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    setstate({ ...state, isUploading: true, progress: 0, imageloading: true });
    if (cover) {
      setcoverLoading(true);
    } else {
      setimgLoading(true);
    }
    try {
      const compressedFile = await imageCompression(imageFile, options);
      console.log(
        'compressedFile instanceof Blob',
        compressedFile instanceof Blob
      ); // true
      console.log(`compressedFile size ${compressedFile.size / 1024} KB`); // smaller than maxSizeMB

      // Create a root reference
      var storageRef = firebase.storage().ref();

      // Create a reference to 'images/mountains.jpg'
      var ref;
      if (cover) {
        ref = storageRef.child(`images/cover_${User.lengthId}`);
        await ref.put(compressedFile);
        handleUploadSuccess(`cover_${User.lengthId}`, cover);
      } else {
        ref = storageRef.child(`images/avatar_${User.lengthId}`);
        await ref.put(compressedFile);
        handleUploadSuccess(`avatar_${User.lengthId}`, cover);
      }

      // await uploadToServer(compressedFile); // write your own logic
    } catch (error) {
      console.log(error);
      setcoverLoading(false);
      setimgLoading(false);
    }
  };
  // const handleUpdateUser = () => {
  //   if (state.avatarURL === '') {
  //     alert('Image is Required');
  //   }
  //   updateUser({ avatarUrl: state.avatarURL });
  // };

  const onChange = (e) => {
    setuser({ ...User, [e.target.name]: e.target.value });
  };
  const onSubmit = (social) => {
    console.log(User);
    if (
      (User.curpassword && !User.newpassword) ||
      (!User.curpassword && User.newpassword)
    )
      return alert('🚫 Invalid Password ');
    if (User.curpassword && User.newpassword && User.newpassword.length < 6)
      return alert('🚫 Password must be 6 or more characters');
    if (
      User.curpassword &&
      User.newpassword &&
      User.curpassword === User.newpassword
    )
      return alert('🚫 New Password cannot be same as old password');
    setloading(true);
    let updatedUser = { ...User, social };
    if (state.avatarURL) updatedUser.avatarUrl = state.avatarURL;
    if (state.coverUrl) updatedUser.coverUrl = state.coverUrl;
    updateUser(updatedUser, history);
    setTimeout(() => {
      setloading(false);
    }, 3000);
  };

  if (Loading) return <Spinner />;
  // if (!user && loading) return <Spinner />;
  if (!isAuth) return <Redirect to="/register" />;

  return (
    <div className="add-pro-photo edit-pro edit-pro2 social-link">
      <div className="container" style={{ padding: '0', overflow: 'hidden' }}>
        <div className="row">
          <div className="col-12">
            <form id="imgForm" style={{ backgroundColor: 'pink' }}>
              <div
                style={{
                  backgroundColor: 'black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  padding: '10px ',
                }}
              >
                <div>
                  {!toggleGear && (
                    <span
                      style={{ color: 'white', cursor: 'pointer' }}
                      onClick={() => setToggleGear(true)}
                    >
                      Settings
                    </span>
                  )}
                </div>
                <div style={{ color: 'white' }}></div>
                <div>
                  {toggleGear ? (
                    <span
                      style={{ color: 'white', cursor: 'pointer' }}
                      onClick={() => setToggleGear(false)}
                    >
                      Back
                    </span>
                  ) : (
                    <Link to={`/${User.userName}`}>
                      <span style={{ color: 'white', cursor: 'pointer' }}>
                        Back
                      </span>{' '}
                    </Link>
                  )}
                </div>
              </div>
            </form>
            {toggleGear ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(user.social);
                }}
                style={{
                  marginTop: '20px',
                }}
              >
                <div className="form-con">
                  <div className="form-con">
                    <span>Login Email</span>
                    <input
                      name="email"
                      type="email"
                      value={User.email}
                      onChange={onChange}
                    />
                  </div>
                  <div
                    className="form-con login1"
                    style={{ margin: 'auto', width: 'fit-content' }}
                  >
                    <button
                      className="btn log-in"
                      onClick={(e) => {
                        e.preventDefault();
                        setactiveChangePassword(!activeChangePassword);
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      Change Password
                    </button>
                  </div>
                  {activeChangePassword && (
                    <div style={{ margin: 'auto' }}>
                      <div
                        className="form-con my-name"
                        style={{ position: 'relative' }}
                      >
                        <span>Current Password</span>
                        <input
                          name="curpassword"
                          type={showPass.curpassword ? 'text' : 'password'}
                          value={User.curpassword}
                          onChange={onChange}
                          autocomplete="off"
                        />
                        <span
                          onClick={() =>
                            setshowPass({
                              ...showPass,
                              curpassword: !showPass.curpassword,
                            })
                          }
                          style={{
                            position: 'absolute',
                            height: '26px',
                            width: '26px',
                            right: '4px',
                            top: '40px',
                            left: 'unset',
                          }}
                        >
                          {!showPass.curpassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </span>
                      </div>
                      <div
                        className="form-con my-name"
                        style={{ position: 'relative' }}
                      >
                        <span>New Password</span>
                        <input
                          name="newpassword"
                          type={showPass.newpassword ? 'text' : 'password'}
                          value={User.newpassword}
                          onChange={onChange}
                          autocomplete="off"
                        />
                        <span
                          onClick={() =>
                            setshowPass({
                              ...showPass,
                              newpassword: !showPass.newpassword,
                            })
                          }
                          style={{
                            position: 'absolute',
                            height: '26px',
                            width: '26px',
                            right: '4px',
                            top: '40px',
                            left: 'unset',
                          }}
                        >
                          {!showPass.newpassword ? (
                            <AiOutlineEye />
                          ) : (
                            <AiOutlineEyeInvisible />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                  {getButton(
                    'edit',
                    user.social,
                    () => {},
                    user.userName,
                    'submit'
                  )}
                </div>
              </form>
            ) : (
              <>
                <div
                  style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div
                    className="cover"
                    style={{
                      backgroundColor: coverLoading && 'white',
                      backgroundImage: coverLoading
                        ? ``
                        : state.coverUrl !== ''
                        ? `url(${state.coverUrl})`
                        : User.coverUrl
                        ? `url(${User.coverUrl})`
                        : `url('https://firebasestorage.googleapis.com/v0/b/digitalcontacts-2699b.appspot.com/o/images%2Fdigital%20contact%20card%20cover%20photo-min.png?alt=media&token=482c6c50-fbde-4594-8eb8-ed86eb4b9970')`,
                    }}
                  >
                    {coverLoading && <Spinner />}
                    <label htmlFor="coverimg" className="overlay">
                      &ensp;
                    </label>
                    <input
                      type="file"
                      id="coverimg"
                      name="coverimg"
                      accept="image/*"
                      onChange={(e) => handleFileChange(e, true)}
                      style={{ display: 'none' }}
                    />
                  </div>
                  <div
                    className="upload-btn-wrapper"
                    style={{
                      position: 'absolute',
                      bottom: '22px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <div
                      style={{
                        position: 'relative',
                        width: '125px',
                        height: '125px',
                      }}
                    >
                      {imgLoading ? (
                        <Spinner />
                      ) : (
                        <img
                          src={
                            state.avatarURL !== ''
                              ? state.avatarURL
                              : User.avatarUrl
                          }
                          id="tmpImg"
                          alt=""
                        />
                      )}
                      <input
                        type="file"
                        id="avatarimg"
                        name="avatarimg"
                        accept="image/*"
                        onChange={(e) => handleFileChange(e, null)}
                        style={{ display: 'none' }}
                      />
                      <label
                        htmlFor="avatarimg"
                        style={{
                          width: '100%',
                          height: '100%',
                          position: 'absolute',
                          left: '0',
                          zIndex: 99,
                        }}
                      />
                      <button className="btnn" type="button">
                        <i className="camera-icon"></i>
                      </button>
                      <small>Change Profile Photo</small>
                    </div>
                    {/* <FileUploader
                      accept="image/*"
                      name="avatar"
                      id="img"
                      randomizeFilename
                      storageRef={firebase.storage().ref('images')}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadSuccess}
                      onProgress={handleProgress}
                      // style={{ width: '200px', display: 'none' }}
                    /> */}
                  </div>
                </div>
                <form>
                  <div className="form-con">
                    <span>Name</span>
                    <input
                      name="name"
                      type="text"
                      value={User.name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-con">
                    <span>My Bio</span>
                    <textarea name="bio" value={User.bio} onChange={onChange} />
                  </div>
                </form>

                <h4>Social Networks</h4>
                <AddSocialsView
                  onSubmit={onSubmit}
                  mode={'edit'}
                  initialState={user.social}
                  id={user.userName}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.registerUser.user,
  isAuth: state.registerUser.isAuth,
  loading: state.registerUser.loading,
});

export default connect(mapStateToProps, { updateUser })(EditProfile);
