import React from 'react';
import logo from '../assets/imgs/DigitalContacts-logo.svg';
import { Link } from 'react-router-dom';
const Terms = () => {
  return (
    <div className='tuto1'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='tuto-ttl'>
              <h1>Contact Card</h1>
              <span>The Business Card Innovation</span>
              <Link to='/'>
                <div className='col-12 text-right p-0'>
                  <img
                    src='https://firebasestorage.googleapis.com/v0/b/digitalcontacts-2699b.appspot.com/o/images%2Fxclose.png?alt=media&token=2f2ef0c7-8090-47f6-974a-2a93cc4f05d6'
                    width='25'
                    alt='QR CODE'
                  />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className='my-profile'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h1>Terms and Conditions</h1>
              <div className='l1'>
                <span>Let Digital Contact Card Intrdouce you</span>
              </div>
              <p>
                Terms of Use Last Modified: JANUARY 5, 2017 Acceptance of the
                Terms of Use These terms of use are entered into by and between
                You and Digital Contact Card. ("Company", "we" or "us"). The
                following terms and conditions (these "Terms of Use"), govern
                your access to and use of the Digital Contact Card℠ website (the
                “Website”) and any Digital Contact Card™ mobile applications
                (each, an “App”), including any content, functionality and
                services offered on or through the Digital Contact Card Website
                or a Digital Contact Card App and under Company’s digital
                control (the Website, Apps, and such additional content,
                functions, and services are, collectively, termed the
                “Service”). Please read the Terms of Use carefully before you
                start to use the Service. By using the Service, or by
                downloading an App and clicking to accept or agree to the Terms
                of Use when this option is made available to you, you accept and
                agree to be bound and abide by these Terms of Use and our
                Privacy Policy, incorporated herein by reference. If you do not
                want to agree to these Terms of Use or the Privacy Policy, you
                must not access or use the Service. This Service is offered and
                available to users who are 13 years of age or older. By using
                this Service, you represent and warrant that you are of legal
                age to form a binding contract with the Company or possess the
                legal consent of your parent or guardian to access and use the
                Service, and meet all of the foregoing eligibility requirements.
                If you do not meet all of these requirements, you must not
                access or use the Service. Changes to the Terms of Use We may
                revise and update these Terms of Use from time to time in our
                sole discretion. All changes are effective immediately when we
                post them, and apply to all access to and use of the Service
                thereafter. Your continued use of the Service following the
                posting of revised Terms of Use means that you accept and agree
                to the changes. You are expected to check this page from time to
                time so you are aware of any changes, as they are binding on
                you. Accessing the Service and Account Security We reserve the
                right to withdraw or amend this Service, and any aspect of the
                Service, in our sole discretion without notice. We will not be
                liable if for any reason all or any part of the Service is
                unavailable at any time or for any period. From time to time, we
                may restrict access to some parts of the Service, or the entire
                Service, to users, including registered users. You are
                responsible for: • Making all arrangements necessary for you to
                have access to the Service. • Ensuring that all persons who
                access the Service through your internet connection or devices
                are aware of these Terms of Use and comply with them. To access
                the Service or some of the resources it offers, you may be asked
                to provide certain registration details or other information. It
                is a condition of your use of the Service that all the
                information you provide on the Service is correct, current and
                complete. You agree that all information you provide to register
                with this Service or otherwise, including but not limited to
                through the use of any interactive features on the Service, is
                governed by our Privacy Policy, and you consent to all actions
                we take with respect to your information consistent with our
                Privacy Policy. If you choose, or are provided with, a user
                name, password or any other piece of information as part of our
                security procedures, you must treat such information as
                confidential, and you must not disclose it to any other person
                or entity. You also acknowledge that your account is personal to
                you and agree not to provide any other person with access to
                this Service or portions of it using your user name, password or
                other security information. You agree to notify us immediately
                of any unauthorized access to or use of your user name or
                password or any other breach of security. You also agree to
                ensure that you exit from your account at the end of each
                session. You should use particular caution when accessing your
                account from a public or shared computer so that others are not
                able to view or record your password or other personal
                information. We have the right to disable any user name,
                password or other identifier, whether chosen by you or provided
                by us, at any time in our sole discretion for any or no reason,
                including if, in our opinion, you have violated any provision of
                these Terms of Use. Intellectual Property Rights The Service and
                its entire contents, features and functionality (including but
                not limited to all information, software, text, displays,
                images, video and audio, and the design, selection and
                arrangement thereof), are owned by the Company, its licensors or
                other providers of such material and are protected by United
                States and international copyright, trademark, patent, trade
                secret and other intellectual property or proprietary rights
                laws. These Terms of Use permit you to use the Service for your
                personal, non-commercial use only. You must not reproduce,
                distribute, modify, create derivative works of, publicly
                display, publicly perform, republish, download, store or
                transmit any of the material on our Service, except as follows:
                • Your computer may temporarily store copies of such materials
                in RAM incidental to your accessing and viewing those materials.
                • You may store files that are automatically cached by your Web
                browser for display enhancement purposes. • You may print [or
                download] one copy of a reasonable number of pages of the
                Website for your own personal, non-commercial use and not for
                further reproduction, publication or distribution. • You may
                download a single copy of each App to your computer or mobile
                device solely for your own personal, non-commercial use,
                provided you agree to be bound by our end user license agreement
                for such applications. You must not: • Modify copies of any
                materials provided by Company. • Delete or alter any copyright,
                trademark or other proprietary rights notices from copies of
                materials from the Service. You must not access or use for any
                commercial purposes any part of the Service. If you print, copy,
                modify, download or otherwise use or provide any other person
                with access to any part of the Service in breach of the Terms of
                Use, your right to use the Service will cease immediately and
                you must, at our option, return or destroy any copies of the
                materials you have made. No right, title or interest in or to
                the Service or any content on the Service is transferred to you,
                and all rights not expressly granted are reserved by the
                Company. Any use of the Service not expressly permitted by these
                Terms of Use is a breach of these Terms of Use and may violate
                copyright, trademark and other laws. Trademarks The Company
                name, the terms Digital Contact Card, EXPAND YOUR NETWORK,
                CONNECTING MADE EASY, FOLLOWING MADE EASY, YOUR ENTIRE AURA IN
                ONE APP, THE NEW WAY TO NETWORK, THE NEW WAY TO CONNECT, SHARE.
                FOLLOW. DISCOVER., CONNECT WITH ME, CONNECT WITH classNameMATES,
                THE NEW WAY TO MAKE FRIENDS, LET Digital Contact Card INTRODUCE
                YOU, EVERYONE-EVERYWHERE-ANYWHERE-ANYTIME, THE NEW WAY TO MAKE
                FRIENDS, YOUR VIRTUAL NAME TAG, AUTO-NETWORKING TECHNOLOGY, the
                Company logo and all related names, logos, product and service
                names, designs and slogans are trademarks of the Company or its
                affiliates or licensors. You must not use such marks without the
                prior written permission of the Company. All other names, logos,
                product and service names, designs and slogans on this Service
                are the trademarks of their respective owners. Prohibited Uses
                You may use the Service only for lawful purposes and in
                accordance with these Terms of Use. You agree not to use the
                Service: • In any way that violates any applicable federal,
                state, local or international law or regulation (including,
                without limitation, any laws regarding the export of data or
                software to and from the US or other countries). • For the
                purpose of exploiting, harming or attempting to exploit or harm
                minors in any way by exposing them to inappropriate content,
                asking for personally identifiable information or otherwise. •
                To send, knowingly receive, upload, download, use or re-use any
                material which does not comply with the Content Standards set
                out in these Terms of Use. • To transmit, or procure the sending
                of, any advertising or promotional material without our prior
                written consent, including any "junk mail", "chain letter" or
                "spam" or any other similar solicitation. • To impersonate or
                attempt to impersonate the Company, a Company employee, another
                user or any other person or entity (including, without
                limitation, by using e-mail addresses or screen names associated
                with any of the foregoing). • To engage in any other conduct
                that restricts or inhibits anyone's use or enjoyment of the
                Service, or which, as determined by us, may harm the Company or
                users of the Service or expose them to liability. Additionally,
                you agree not to: • Use the Service in any manner that could
                disable, overburden, damage, or impair the Service or interfere
                with any other party's use of the Service, including their
                ability to engage in real time activities through the Service. •
                Use any robot, spider or other automatic device, process or
                means to access the Service for any purpose, including
                monitoring or copying any of the material on the Service. • Use
                any manual process to monitor or copy any of the material on the
                Service or for any other unauthorized purpose without our prior
                written consent. • Use any device, software or routine that
                interferes with the proper working of the Service. • Introduce
                any viruses, Trojan horses, worms, logic bombs or other material
                which is malicious or technologically harmful. • Attempt to gain
                unauthorized access to, interfere with, damage or disrupt any
                parts of the Service or any server, computer or database
                connected to the Service. • Attack the Service via a
                denial-of-service attack or a distributed denial-of-service
                attack. • Otherwise attempt to interfere with the proper working
                of the Service. User Contributions The Service may contain
                message boards, chat rooms, personal web pages or profiles,
                forums, bulletin boards and other interactive features
                (collectively, "Interactive Services") that allow users to post,
                submit, publish, display or transmit to other users or other
                persons (hereinafter, "post") content or materials
                (collectively, "User Contributions") on or through the Service.
                All User Contributions must comply with the Content Standards
                set out in these Terms of Use. Any User Contribution you post to
                the site will be considered non-confidential and
                non-proprietary. By providing any User Contribution on the
                Service, you grant us and our affiliates and service providers,
                and each of their and our respective licensees, successors and
                assigns the right to use, reproduce, modify, perform, display,
                distribute and otherwise disclose to third parties any such
                material according to your account settings. You represent and
                warrant that: • You own or control all rights in and to the User
                Contributions and have the right to grant the license granted
                above to us and our affiliates and service providers, and each
                of their and our respective licensees, successors and assigns. •
                All of your User Contributions do and will comply with these
                Terms of Use. You understand and acknowledge that you are
                responsible for any User Contributions you submit or contribute,
                and you, not the Company, have full responsibility for such
                content, including its legality, reliability, accuracy and
                appropriateness. We are not responsible, or liable to any third
                party, for the content or accuracy of any User Contributions
                posted by you or any other user of the Service. Monitoring and
                Enforcement; Termination We have the right to: • Remove or
                refuse to post any User Contributions for any or no reason in
                our sole discretion. • Take any action with respect to any User
                Contribution that we deem necessary or appropriate in our sole
                discretion, including if we believe that such User Contribution
                violates the Terms of Use, including the Content Standards,
                infringes any intellectual property right or other right of any
                person or entity, threatens the personal safety of users of the
                Service or the public or could create liability for the Company.
                • Disclose your identity or other information about you to any
                third party who claims that material posted by you violates
                their rights, including their intellectual property rights or
                their right to privacy. • Take appropriate legal action,
                including without limitation, referral to law enforcement, for
                any illegal or unauthorized use of the Service. • Terminate or
                suspend your access to all or part of the Service for any or no
                reason, including without limitation, any violation of these
                Terms of Use. Without limiting the foregoing, we have the right
                to fully cooperate with any law enforcement authorities or court
                order requesting or directing us to disclose the identity or
                other information of anyone posting any materials on or through
                the Service. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS
                AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS
                RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
                DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS
                TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES
                OR LAW ENFORCEMENT AUTHORITIES. However, we do not undertake to
                review all material before it is posted on the Service, and
                cannot ensure prompt removal of objectionable material after it
                has been posted. Accordingly, we assume no liability for any
                action or inaction regarding transmissions, communications or
                content provided by any user or third party. We have no
                liability or responsibility to anyone for performance or
                nonperformance of the activities described in this section.
                Content Standards These content standards apply to any and all
                User Contributions and use of Interactive Services. User
                Contributions must in their entirety comply with all applicable
                federal, state, local and international laws and regulations.
                Without limiting the foregoing, User Contributions must not: •
                Contain any material which is defamatory, obscene, indecent,
                abusive, offensive, harassing, violent, hateful, inflammatory or
                otherwise objectionable. • Promote sexually explicit or
                pornographic material, violence, or discrimination based on
                race, sex, religion, nationality, disability, sexual orientation
                or age. • Infringe any patent, trademark, trade secret,
                copyright or other intellectual property or other rights of any
                other person. • Violate the legal rights (including the rights
                of publicity and privacy) of others or contain any material that
                could give rise to any civil or criminal liability under
                applicable laws or regulations or that otherwise may be in
                conflict with these Terms of Use and our Privacy Policy. • Be
                likely to deceive any person. • Promote any illegal activity, or
                advocate, promote or assist any unlawful act. • Cause annoyance,
                inconvenience or needless anxiety or be likely to upset,
                embarrass, alarm or annoy any other person. • Impersonate any
                person, or misrepresent your identity or affiliation with any
                person or organization. • Involve commercial activities or
                sales, such as contests, sweepstakes and other sales promotions,
                barter or advertising. • Give the impression that they emanate
                from or are endorsed by us or any other person or entity, if
                this is not the case. Social Content When you log in, we will
                collect the information you have made publicly available through
                creation of your Digital Contact Card account and/or made
                publicly available by your other “Social Networks” (i.e., any
                social networking site or app, such as Facebook, Twitter,
                Instagram, Snapchat, Pinterest, LinkedIn, Google, etc. for which
                you have an account), or have otherwise authorized your Social
                Networks to share with third-party applications like ours, such
                as your name, profile picture, friends, email address, location,
                education, and employment information (collectively, “Social
                Content”). You agree that we may use your Social Content to
                populate your user profile on the Service and/or to suggest
                prospective user connections to you. Company claims no ownership
                or control over your Social Content. You, or a third-party
                licensor, as appropriate, retain all copyright, patent, and
                trademark rights to any of your Social Content. By using the
                Service, you grant to Company a worldwide, non-exclusive,
                royalty-free, transferable license to use, modify, remove,
                publish, transmit, or display your Social Content in order to
                facilitate the display of your Social Content to you and other
                users. Further, you agree that Company can post content or
                otherwise provide information to your other Social Networks. You
                represent and warrant that: i) you own your Social Content or
                otherwise have the right to grant the license above, ii) the use
                of your Social Content on or through the Service does not
                violate any rights of any other person, and iii) the posting of
                your Social Content on the Service does not result in the breach
                of contract between you and any third party. Messaging The
                Service may allow you to send messages to other users of the
                service. Company may suspend or terminate such messaging
                privileges of any user at any time for any reason, without
                liability to the user. You agree that Company may monitor
                messages for compliance with these Terms of Use, and therefore
                should not be considered confidential. You hereby grant Company
                an irrevocable license to reproduce and transmit your messages
                in connection with facilitating transmission to the intended
                recipient through the Service. Company may offer you the ability
                to invite other potential users to the Service by sending them a
                text message, email, or invite through other social media
                channels. You hereby agree to indemnify, defend, and otherwise
                hold Company harmless from any and all claims arising out of
                your sending of an invitation to another potential user. You are
                responsible for all fees and charges associated with sending
                such invites. Collection of Data You agree that Company may
                collect and use technical data and related information,
                including, but not limited to, UDID, device location, and other
                technical and diagnostic information about your device and
                operating system to facilitate the provision of software
                updates, product support, location based services, and to
                anonymously track and report your activity inside of the
                Service, including for analytics purposes. Copyright
                Infringement If you believe that any User Contributions violate
                your copyright, please see our Copyright Policy for instructions
                on sending us a notice of copyright infringement. It is the
                policy of the Company to terminate the user accounts of repeat
                infringers. Reliance on Information Posted The information
                presented on or through the Service is made available solely for
                general information purposes. We do not warrant the accuracy,
                completeness or usefulness of this information. Any reliance you
                place on such information is strictly at your own risk. We
                disclaim all liability and responsibility arising from any
                reliance placed on such materials by you or any other user of
                the Service, or by anyone who may be informed of any of its
                contents. This Service includes content provided by third
                parties, including materials provided by other users, bloggers
                and third-party licensors, syndicators, aggregators and/or
                reporting services. All statements and/or opinions expressed in
                these materials, and all articles and responses to questions and
                other content, other than the content provided by the Company,
                are solely the opinions and the responsibility of the person or
                entity providing those materials. These materials do not
                necessarily reflect the opinion of the Company. We are not
                responsible, or liable to you or any third party, for the
                content or accuracy of any materials provided by any third
                parties. Changes to the Service We may update the content on
                this Service from time to time, but its content is not
                necessarily complete or up-to-date. Any of the material on the
                Service may be out of date at any given time, and we are under
                no obligation to update such material. Information About You and
                Your Use of the Service All information we collect on this
                Service is subject to our Privacy Policy. By using the Service,
                you consent to all actions taken by us with respect to your
                information in compliance with the Privacy Policy. Linking to
                the Website and Social Media Features You may link to our
                homepage, provided you do so in a way that is fair and legal and
                does not damage our reputation or take advantage of it, but you
                must not establish a link in such a way as to suggest any form
                of association, approval or endorsement on our part without our
                express written]consent. This Service may provide certain social
                media features that enable you to: • Link from your own or
                certain third-party websites to certain content on this Service.
                • Send e-mails or other communications with certain content, or
                links to certain content, on this Service. • Cause limited
                portions of content on this Service to be displayed or appear to
                be displayed on your own or certain third-party websites. You
                may use these features solely as they are provided by us, solely
                with respect to the content they are displayed with, and
                otherwise in accordance with any additional terms and conditions
                we provide with respect to such features. Subject to the
                foregoing, you must not take any action with respect to the
                materials on this Service that is inconsistent with any other
                provision of these Terms of Use. We may disable all or any
                social media features and any links at any time without notice
                in our discretion. Links from the Service If the Service
                contains links to other sites and resources provided by third
                parties, these links are provided for your convenience only.
                This includes links contained in advertisements, including
                banner advertisements and sponsored links. We have no control
                over the contents of those sites or resources, and accept no
                responsibility for them or for any loss or damage that may arise
                from your use of them. If you decide to access any of the third
                party websites linked to this Service, you do so entirely at
                your own risk and subject to the terms and conditions of use for
                such websites. Geographic Restrictions The owner of the Service
                is based in the state of California in the United States. We
                make no claims that the Service or any of its content is
                accessible or appropriate outside of the United States. Access
                to the Service may not be legal by certain persons or in certain
                countries. If you access the Service from outside the United
                States, you do so on your own initiative and are responsible for
                compliance with local laws. Disclaimer of Warranties You
                understand that we cannot and do not guarantee or warrant that
                files available for downloading from the internet or the Service
                will be free of viruses or other destructive code. You are
                responsible for implementing sufficient procedures and
                checkpoints to satisfy your particular requirements for
                anti-virus protection and accuracy of data input and output, and
                for maintaining a means external to our site for any
                reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY
                LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK,
                VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY
                INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
                PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICE OR TO YOUR
                DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE
                LINKED TO IT. YOUR USE OF THE SERVICE IS AT YOUR OWN RISK. THE
                SERVICE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE SERVICE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
                WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
                NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
                MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
                COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
                AVAILABILITY OF THE SERVICE. WITHOUT LIMITING THE FOREGOING,
                NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY
                REPRESENTS OR WARRANTS THAT THE SERVICE, ITS CONTENT OR ANY
                SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE WILL BE ACCURATE,
                RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
                CORRECTED, THAT OUR SERVICE OR THE SERVER THAT MAKES IT
                AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR
                THAT THE SERVICE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
                SERVICE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. THE
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER
                EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT
                LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT
                AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT
                AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER
                APPLICABLE LAW. Limitation on Liability IN NO EVENT WILL THE
                COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS,
                EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES
                OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
                CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICE, ANY
                WEBSITES LINKED TO IT, ANY CONTENT ON THE SERVICE OR SUCH OTHER
                WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICE
                OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT
                LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL
                DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
                OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE FOREGOING
                DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
                LIMITED UNDER APPLICABLE LAW. Indemnification You agree to
                defend, indemnify and hold harmless the Company, its affiliates,
                licensors and service providers, and its and their respective
                officers, directors, employees, contractors, agents, licensors,
                suppliers, successors and assigns from and against any claims,
                liabilities, damages, judgments, awards, losses, costs, expenses
                or fees (including reasonable attorneys' fees) arising out of or
                relating to your violation of these Terms of Use or your use of
                the Service, including, but not limited to, your User
                Contributions, any use of the Service’s content, services and
                products other than as expressly authorized in these Terms of
                Use or your use of any information obtained from the Service.
                Governing Law and Jurisdiction All matters relating to the
                Service and these Terms of Use and any dispute or claim arising
                therefrom or related thereto (in each case, including
                non-contractual disputes or claims), shall be governed by and
                construed in accordance with the internal laws of the State of
                California without giving effect to any choice or conflict of
                law provision or rule (whether of the State of California or any
                other jurisdiction). Any legal suit, action or proceeding
                arising out of, or related to, these Terms of Use or the Service
                shall be instituted exclusively in the federal courts of the
                United States or the courts of the State of California and
                County of Riverside. You waive any and all objections to the
                exercise of jurisdiction over you by such courts and to venue in
                such courts. Arbitration At Company's sole discretion, it may
                require You to submit any disputes arising from the use of these
                Terms of Use or the Service, including disputes arising from or
                concerning their interpretation, violation, invalidity,
                non-performance, or termination, to final and binding
                arbitration under the Rules of Arbitration of the American
                Arbitration Association applying California law. Waiver and
                Severability No waiver of by the Company of any term or
                condition set forth in these Terms of Use shall be deemed a
                further or continuing waiver of such term or condition or a
                waiver of any other term or condition, and any failure of the
                Company to assert a right or provision under these Terms of Use
                shall not constitute a waiver of such right or provision. If any
                provision of these Terms of Use is held by a court or other
                tribunal of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision shall be eliminated
                or limited to the minimum extent such that the remaining
                provisions of the Terms of Use will continue in full force and
                effect. Entire Agreement The Terms of Use and our Privacy Policy
                constitute the sole and entire agreement between you and us,
                Inc. with respect to the Service and supersede all prior and
                contemporaneous understandings, agreements, representations and
                warranties, both written and oral, with respect to the Service.
                All notices of copyright infringement claims should be sent to
                the copyright agent designated in our Copyright Policy in the
                manner and by the means set forth therein. All other feedback,
                comments, requests for technical support and other
                communications relating to the Service should be directed to:
                sales@digitalcontactcard.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
