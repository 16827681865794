import React, { useState } from 'react';
import qr from '../assets/imgs/qr-code.svg';
import { Link, Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Footer from './Footer';
import Spinner from './Spinner';
import { logout, updateClicks } from '../actions/registerUser';
import PublicProfile from './PublicProfile';
import QrScan from './QrScan';
import ListItem from './ListItem';

const Dashboard = ({
  authh: { isAuth, loading },
  logout,
  user,
  updateClicks,
}) => {
  const [show, setshow] = useState('');
  const { id } = useParams();

  if ((!user || id !== user.userName) && !loading) {
    return <PublicProfile />;
  }

  const handleClicks = (name) => {
    let social = {
      ...user.social,
      [name]: {
        ...user.social[name],
        clicks: user.social[name].clicks + 1,
      },
    };
    updateClicks({ social }, user._id);
  };

  if (loading || !user.social) return <Spinner />;
  else
    return (
      <div>
        <div className="my-profile">
          <div className="container" style={{ padding: '0' }}>
            <div
              className="tuto1"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '10px 10px',
                color: 'white',
              }}
            >
              <Link
                to="/edit_profile"
                className="edit1"
                style={{ color: 'white' }}
              >
                Edit Profile
              </Link>
              <div style={{ color: 'white' }}>Profile views {user.views}</div>
              <Link to="/login" onClick={logout} style={{ color: 'white' }}>
                Log out
              </Link>
            </div>
            <div className="row" style={{ margin: 0 }}>
              {/* <div
                className="col-12 text-right pt-2 pl-2 pr-2"
                onClick={() => setshow('show')}
              >
                <img
                  src={qr}
                  width="25"
                  className="showPopup"
                  target="#profileQrCon"
                />
              </div> */}

              <div
                className="col-12 cover"
                style={{
                  backgroundImage: user.coverUrl
                    ? `url(${user.coverUrl})`
                    : `url('https://firebasestorage.googleapis.com/v0/b/digitalcontacts-2699b.appspot.com/o/images%2Fdigital%20contact%20card%20cover%20photo-min.png?alt=media&token=482c6c50-fbde-4594-8eb8-ed86eb4b9970')`,
                }}
              >
                <div className="wrapper">
                  <div className="my-profile-photo">
                    <img src={user.avatarUrl} alt="photo" id="profileImg" />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <h1 id="name">{user.name} </h1>

                <p id="bio" style={{ whiteSpace: 'pre-line' }}>
                  {user.bio}
                </p>
              </div>
              <b className="text-center mt-2 mb-2 d-block">
                <div className="col-12 social2">
                  <ul className="row">
                    {Object.keys(user.social).map(
                      (username) =>
                        user.social[username].value !== '' && (
                          <ListItem
                            user={user}
                            handleClicks={handleClicks}
                            username={username}
                            isPublic={false}
                            size={'14px'}
                          />
                        )
                    )}
                  </ul>
                </div>
              </b>
            </div>
          </div>
          <Footer />
          <QrScan user={user} show={show} setshow={setshow} />
        </div>
      </div>
    );
};

const mapStateToProps = (state) => ({
  authh: state.registerUser,
  user: state.registerUser.user,
});

export default connect(mapStateToProps, { logout, updateClicks })(Dashboard);
