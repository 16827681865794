export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_PROFILE = "GET_PROFILE";
export const ADD_EXPERIENCE = "ADD_EXPERIENCE";
export const ADD_EDUCATION = "ADD_EDUCATION";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const ADD_PIC = "ADD_PIC";
export const ADD_BIO = "ADD_BIO";
export const ADD_SOCIAL = "ADD_SOCIAL";
export const UPDATE_FAIL = "UPDATE_FAIL";
export const PASS_ERROR = "PASS_ERROR";
