import React, { useEffect, useState } from 'react';
import { Link, Redirect, useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/registerUser';
import axios from 'axios';
import Footer from './Footer';
import Spinner from './Spinner';
import QrScan from './QrScan';
import ListItem from './ListItem';
import { FaShare } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';

const getid = (hash) => {
  let index = hash.indexOf('-');
  let result = hash.slice(index + 1);
  return result;
};

const PublicProfile = ({ authh: { isAuth, loading }, logedUser }) => {
  const history = useHistory();
  const { id } = useParams();
  const [user, setuser] = useState();
  const [show, setshow] = useState('show1');
  const [error, seterror] = useState(null);
  const parsed = parseInt(getid(id), 10);
  const [state, setState] = useState('Copy link to clipboard');

  useEffect(() => {
    (() => {
      if (!isNaN(parsed)) {
        axios
          .get(`/api/users/myusername/${id}`)
          .then((res) => {
            const user = res.data;
            history.push(`/${user.userName}`);
          })
          .catch(() => {
            axios
              .get(`/api/users/current/${id}`)
              .then((user) => setuser(user.data))
              .catch(() => {
                seterror('not Found');
              });
          });
      } else {
        axios
          .get(`/api/users/current/${id}`)
          .then((user) => setuser(user.data))
          .catch(() => {
            seterror('not Found');
          });
      }
    })();
  }, [id]);

  const handleClicks = async (name) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    let social = {
      ...user.social,
      [name]: {
        ...user.social[name],
        clicks: user.social[name].clicks + 1,
      },
    };
    const body = JSON.stringify({ social });

    try {
      const u = await axios.post(
        `/api/users/update_clicks/${user._id}`,
        body,
        config
      );
      setuser(u.data);
    } catch (err) {}
  };

  if (error) return <Redirect to="/login" />;

  if (logedUser !== null && id === logedUser.userName)
    return <Redirect to="/login" />;

  if (loading || !user) return <Spinner />;

  return (
    <div>
      <div className="my-profile">
        <div className="container" style={{ padding: '0', overflow: 'hidden' }}>
          <div
            style={{
              backgroundColor: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '10px 10px',
            }}
          >
            <div
              style={{ backgroundColor: 'white', padding: '1px' }}
              onClick={() => setshow('show')}
            >
              <img
                src="https://firebasestorage.googleapis.com/v0/b/digitalcontacts-2699b.appspot.com/o/images%2Fqr-code.svg?alt=media&token=ed5b1895-ce13-42ff-abb6-605d5313f6bf"
                width="25"
                className="showPopup"
                target="#profileQrCon"
              />
            </div>
            <div style={{ color: 'white' }}>Profile views {user.views}</div>
            <div>
              {isAuth ? (
                <Link to="/login" style={{ color: 'white' }}>
                  Profile
                </Link>
              ) : (
                <Link to="/login" style={{ color: 'white' }}>
                  Login
                </Link>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div
                className="col-12 cover"
                style={{
                  backgroundImage: user.coverUrl
                    ? `url(${user.coverUrl})`
                    : `url('https://firebasestorage.googleapis.com/v0/b/digitalcontacts-2699b.appspot.com/o/images%2Fdigital%20contact%20card%20cover%20photo-min.png?alt=media&token=482c6c50-fbde-4594-8eb8-ed86eb4b9970')`,
                }}
              >
                <div className="wrapper">
                  <div className="my-profile-photo">
                    <img src={user.avatarUrl} alt="photo" id="profileImg" />
                  </div>
                </div>
              </div>
              <h1 id="name">{user.name}</h1>

              <p id="bio" style={{ whiteSpace: 'pre-line' }}>
                {user.bio}
              </p>
              <div className="d-flex">
                <div
                  className="col-10"
                  id="btnDownloadVcard"
                  style={{ padding: 0 }}
                >
                  <a
                    href={`https://www.digitalcontacts.info/api/users/vcf/${user._id}`}
                    download
                    className="btn"
                  >
                    Add to Contacts
                  </a>
                </div>

                {/* TooltTip functionality */}
                <div className="col-2">
                  <span
                    className="position-relative btn px-3 py-2 "
                    style={{
                      height: '50px',
                      minWidth: '50px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    data-tip=""
                    data-for="registerTip3"
                    onMouseLeave={() => setState('Copy link')}
                    onClick={async () => {
                      navigator.clipboard.writeText(user.tinyUrl);
                      setState('Copied!');
                    }}
                  >
                    <FaShare className="btn_link" />
                    <ReactTooltip id="registerTip3" place="top" effect="solid">
                      {state}
                    </ReactTooltip>
                  </span>
                </div>
              </div>

              <b className="text-center mt-2 mb-2 d-block">
                <div className="col-12 social2">
                  <ul className="row">
                    {user.social &&
                      Object.keys(user.social).map(
                        (username) =>
                          user.social[username].value !== '' && (
                            <ListItem
                              user={user}
                              handleClicks={handleClicks}
                              username={username}
                              isPublic={true}
                              size={'18px'}
                            />
                          )
                      )}
                  </ul>
                </div>
              </b>
            </div>
            <b className="text-center mt-2 mb-2 d-block"></b>
          </div>
          <b className="text-center mt-2 mb-2 d-block"></b>
        </div>
        <b className="text-center mt-2 mb-2 d-block"></b>
      </div>
      <Footer />

      <QrScan user={user} show={show} setshow={setshow} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  authh: state.registerUser,
  logedUser: state.registerUser.user,
});

export default connect(mapStateToProps, { logout })(PublicProfile);
