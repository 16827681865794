import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import logo from '../assets/imgs/DigitalContacts-logo.svg'
import { connect } from 'react-redux'
import { loginUser } from '../actions/registerUser'
import ShopNow from './ShopNow.js'

const Login = ({ loginUser, isAuth }) => {
  const [loginData, setLoginData] = useState({
    email: '',
    password: '',
  })
  const [shopNow, toggleShopNow] = useState('')
  const { email, password } = loginData
  const onChangeHandler = (e) =>
    setLoginData({ ...loginData, [e.target.name]: e.target.value })

  const onSubmitHandler = (e) => {
    e.preventDefault()

    loginUser(email, password)
  }

  if (isAuth) {
    return <Redirect to="/add_profile_image" />
  }
  return (
    <div class="my-name create-account wel-back">
      <div className="container">
        <div className="row">
          <form onSubmit={(e) => onSubmitHandler(e)}>
            <div className="col-12">
              <div className="login1">
                <Link to="login" className="account1">
                  Got your card?
                </Link>
                <span
                  style={{ marginLeft: '10px' }}
                  onClick={() => toggleShopNow('show')}
                  className="log-in"
                >
                  Get Now
                </span>
              </div>
              <div className="logo">
                <img src={logo} alt="logo" />
              </div>
              <div className=" headline">
                <h1>Welcome back, To Digital Contact Card!</h1>
              </div>
              <div className="headline">
                <p className="p1">
                  Enter your email address and password to access your account.
                </p>
              </div>
              <input
                type="email"
                name="email"
                placeholder="Email"
                onChange={(e) => onChangeHandler(e)}
                value={loginData.email}
                required
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                onChange={(e) => onChangeHandler(e)}
                value={loginData.password}
                required
              />
              <button
                className="btn w-100prc"
                type="submit"
                id="submitBtn"
                style={{ marginTop: '14px' }}
              >
                Log In
              </button>
              <Link to="reset_password" className="forgot">
                Forgot Password?
              </Link>
            </div>
          </form>
        </div>
      </div>
      <ShopNow show={shopNow} setshow={toggleShopNow} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  isAuth: state.registerUser.isAuth,
})

export default connect(mapStateToProps, { loginUser })(Login)
