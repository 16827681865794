import React from 'react'
import xclose from '../assets/xclose.png'
import logo from '../assets/DigitalContacts-logo.svg'
var QRCode = require('qrcode.react')

export default function ShopNow({ user, show, setshow }) {
  return (
    <div>
      <div className={`col-12 ${show}`} id="profileQrCon">
        <div className="col-12 text-right pt-4 p-0" onClick={() => setshow('')}>
          <img
            src={xclose}
            width="25"
            className="clsPopup"
            target="#profileQrCon"
            alt="QR CODE"
          />
        </div>
        <div
          className="col-12 r2 d-flex text-center align-items-center justify-content-center "
          style={{ minHeight: '90vh' }}
        >
          <a
            href="https://digitalcontactcard.com/shop"
            target="_blank"
            className="btn "
            type="submit"
            id="submitBtn"
            style={{
              marginTop: '14px',
              marginTop: '14px',
              display: 'flex',
              width: '50%',
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
            }}
          >
            Shop Now
            <img
              width="20"
              src="https://icon-library.com/images/shopping-cart-icon-white/shopping-cart-icon-white-11.jpg"
            />{' '}
          </a>
        </div>
      </div>
    </div>
  )
}
