import React from 'react';

const Footer = () => {
  return (
    <div>
      <div className='pwrd text-center'>
        <a className='buyNow'>
          <a className='buyNow'>
            &copy; Powered by{' '}
            <span>
              <a href='https://digitalcontactcard.com/'>Digital Contact Card</a>
            </span>
          </a>
        </a>
      </div>

      <div className='text-center mb-1'>
        <a href='https://digitalcontactcard.com/faq/'>
          Frequently Asked Questions
        </a>
      </div>
    </div>
  );
};
export default Footer;
